import React,{useEffect, useState} from "react";
import condusef from "../../img/condusef.png";
import sat from "../../img/sat.png";
import asofam from "../../img/asofom.png";
import buro_credito from "../../img/buro_credito.png";
import buro from "../../img/buro.png";
import logo_balnco from "../../img/logo_blanco.png";
import icono_ubi from "../../img/icono-ubi.png";
import icono_mail from "../../img/icono-mail.png";
import icono_phone from "../../img/icono-phone.png";
import icono_what from "../../img/icono-what.png";
import icono_fb from "../../img/icono-fb.png";
import icono_ig from "../../img/icono-ig.png";
import icono_yt from "../../img/icono-yt.png";
import materialized_blueberry from "../../img/materialized-blueberry.gif";
import Pdf from "../portal/pagina/pdf/catycomisiones.pdf";
import momentTime from 'moment-timezone';
import sipres from "../../img/logo_sipres.jpg"

export const Footer = () => {
  const [currentYear, setCurrentYear] = useState(2022)
  useEffect(() => {
    setCurrentYear(momentTime(new Date()).tz('America/Mexico_City').format("YYYY"))
  },[])
  return (
    <div>
      <div class="d-none d-sm-none d-md-block p-0 m-0 text-center">
        <div class="">
          <br />
          <p>
            <h5>
            Dirección: En ZMG ven y solicita tu crédito en Calle Tomás V. Gómez 307, Ladrón De Guevara C.P. , 44600 Guadalajara, Jal.
            </h5>
          </p>
          <p>
            <h5>
            Teléfono: 33 3616 8059
            </h5>
          </p>
          <p>
            <h5>
            Correo: contacto@percapita.mx 
            </h5>
          </p>
        </div>
        <h2 class="container-range">
          Comisiones Asociadas
        </h2>
        <div class="footer-clients d-flex justify-content-center row">
          <div class="text-center image-range">
            <a href="https://www.gob.mx/condusef" target="_blank">
              <img src={condusef} alt="Condusef" class="img-fluid p-3 img-client" width="160" />
            </a>
            <a href="https://www.gob.mx/condusef" target="_blank">
              <img src={sipres} alt="Condusef" class="img-fluid p-3 img-client" width="160" />
            </a>
            <p class="footer-text">
              Percapita es una entidad financiera SOFOM ENR supervisada por autoridades mexicanas puedes consultar nuestro registro en el portal SIPRES de la Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros.
              Percapita no te pedirá acceso a tus contactos o fotos. Puedes revisar nuestro Aviso de Privacidad en <a href="https://percapita.mx/Aviso-de-privacidad">https://percapita.mx/Aviso-de-privacidad</a> </p>
          </div>
          <div class="text-center image-range">
            <a href="https://www.sat.gob.mx/home" target="_blank">
              <img src={sat} alt="SAT" class="img-fluid p-3 img-client" width="100" />
            </a>
            <p class="footer-text">
              Productos ofertados por Probenso Group, S.A., SOFOM, E.N.R., quién indica que, no requiere autorización de la Secretaría de Hacienda y Crédito Público para su constitución y realizar operaciones como Sociedad Financiera de Objeto Múltiple Entidad no Regulada y se encuentra sujeta a la supervisión de la Comisión Nacional Bancaria y de Valores, únicamente para efectos del artículo 56 de la Ley general de organizaciones y actividades auxiliares del crédito.
            </p>
          </div>
          <div class="text-center image-range">
            <a href="https://asofom.mx" target="_blank">
              <img src={asofam} alt="Asofom" class="img-fluid p-3 img-client" width="140" />
            </a>
            <p class="footer-text">
              Probenso Group, S.A., SOFOM, E.N.R. es una entidad financiera asociada a la Asociación de Sociedades Financieras de Objeto Múltiple en México, A.C. y nos puedes encontrar en el directorio en el siguiente link <a href="https://asofom.mx/">https://asofom.mx/</a>
            </p>
          </div>
          <div class="text-center image-range">
            <a href="https://www.burodecredito.com.mx" target="_blank">
              <img src={buro_credito} alt="Buró de Crédito" class="img-fluid p-3 img-client" width="95" />
            </a>
            <a href="http://www.buro.gob.mx" target="_blank">
              <img src={buro} alt="Buró" class="img-fluid p-3 img-client" width="95" />
            </a>
            <p class="footer-text">
              El Buró de Entidades Financieras contiene información de PROBENSO GROUP S.A SOFOM E.N.R sobre nuestro desempeño frente a los usuarios, por la prestación de productos y servicios. Te invitamos a consultarlo también en la página <a href="http://www.buro.gob.mx">http://www.buro.gob.mx</a> o en <a href="https://app.percapita.mx/buro">https://app.percapita.mx/buro</a> 
            </p>
          </div>
        </div>
        
      </div>
      <div class="p-5 footer-text">
        <div class="container">
          <p>
            Desde $100 hasta $24,500. Periodo mínimo y máximo de repago podrá ser de 15 hasta 180 días en 1 o 12 cuotas a elección de la solicitud del cliente y al producto de crédito aprobado. APR (Tasa de interés anual) mínima 0% y máxima de 300%. Un ejemplo representativo del costo total del Préstamo considerando la tasa de interés promedio diaria sería el siguiente: Por $2,000 pagarías $2,972.28 incluyendo IVA de los intereses (Cálculo realizado con una tasa de interés diaria del 0.82%, y un plazo de 12 semanas. CAT Promedio 300%, (calculado al 23 de enero del 2025). Percapita únicamente realiza operaciones en México.
          </p>
          <p>Es obligatorio cumplir con los siguientes requisitos: ser mayor de edad, nacionalidad mexicana, domicilio en territorio nacional y credencial para votar original vigente.</p>
          <p>Percapita únicamente realiza operaciones en México.</p>
          <p>
          Atención a usuarios de la Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros, para cualquier cuestión respecto de los servicios financieros prestados por Percapita , los datos de contacto de la CONDUSEF son los siguientes: 55 5340-0999  asimismo le informa que la página de internet de la citada Comisión es <a href="https://www.condusef.gob.mx" target="_blank">www.condusef.gob.mx</a> y que el correo electrónico al que podrá enviar sus dudas es el <a href='mailto:asesoria@condusef.gob.mx'> asesoria@condusef.gob.mx.</a>
          </p>
        </div>
      </div>

      <div class="d-none d-sm-none d-md-block">
        <footer>
          <div class="bg-footer">
            <div class="container py-5">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-1"></div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-10">
                  <div class="text-center">
                    <img src={logo_balnco} alt="PERCAPITA" class="img-fluid" />
                  </div>
                  <div class="row my-5">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4">
                      <div class="contacto-footer">
                        <h3 class="rExtraBold txt-azul">Contacto</h3>
                        <div class="mt-3">
                        <img
                            src={icono_ubi}
                            alt="Encuentranos en: "
                            className="img-fluid icono-contacto mb-5"
                          />
                          <aside>
                            <a
                              href="https://maps.app.goo.gl/hvWmTwuNVcjdDCLi6"
                              className="a_none_style text-white"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h6 className="oRegular">
                              Calle Tomás V. Gómez 307
                              </h6> 
                              <h6 className="oRegular">
                              Ladrón de Guevara, Ladrón De Guevara
                              </h6>
                              <h6 className="oRegular">
                              44600 Guadalajara, Jal.
                              </h6>
                            </a>
                          </aside>
                          <img
                            src={icono_ubi}
                            alt="Encuentranos en: "
                            class="img-fluid icono-contacto mb-5"
                          />
                          <aside>
                            <a
                              href="https://maps.app.goo.gl/hvWmTwuNVcjdDCLi6"
                              class="a_none_style text-white"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h6 class="oRegular">
                              Ures Sur 137 5 
                              </h6>
                              <h6 class="oRegular">
                              Col. Centro, 63000 
                              </h6>
                              <h6 class="oRegular">
                              Tepic, Nayarit.
                              </h6>
                            </a>
                          </aside>
                          <img
                            src={icono_mail}
                            alt="Contactanos en:"
                            class="img-fluid icono-contacto"
                          />
                          <a
                            href="mailto:contacto@percapita.mx?Subject=Solicitar%20Informes%20Percapita"
                            class="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h6 class="oRegular my-3">contacto@percapita.mx</h6>
                          </a>
                          <img
                            src={icono_phone}
                            alt="Contactanos al:"
                            class="img-fluid icono-contacto"
                          />
                          <a
                            href="tel:+523336168059"
                            class="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h6 class="oRegular mb-3">33 3616-8059</h6>
                          </a>
                          <img
                            src={icono_what}
                            alt="Contactanos al:"
                            class="img-fluid icono-contacto"
                          />
                          <a
                            href="https://api.whatsapp.com/send?phone=523346548542"
                            class="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h6 class="oRegular">33 4654 8542</h6>
                            
                          
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-2">
                      <div class="menu-footer">
                        <h3 class="rExtraBold txt-azul">Menú</h3>
                        <div class="mt-3">
                          <a href="/" class="a_none_style text-white">
                            <h6 class="oRegular">Inicio</h6>
                          </a>
                          <a href="/Nosotros" class="a_none_style text-white">
                            <h6 class="oRegular">Nosotros</h6>
                          </a>
                          {/* <a href="/Financiamiento" class="a_none_style text-white">
                            <h6 class="oRegular">Financiamiento</h6>
                          </a> */}
                          {/* <a href="https://app.percapita.mx/" class="a_none_style text-white">
                            <h6 class="oRegular">Crédito PABS</h6>
                          </a> */}
                          <a
                            href="https://app.percapita.mx/Titulo?utm_source=percapita.mx&utm_medium=search&utm_campaign=none"
                            class="a_none_style text-white"
                          >
                            <h6 class="oRegular">Crédito PABS</h6>
                          </a>
                          <a
                            href="https://percapita.mx/Credito-personal"
                            class="a_none_style text-white"
                          >
                            <h6 class="oRegular">Crédito Personal</h6>
                          </a>
                          <a href="/Blog" class="a_none_style text-white">
                            <h6 class="oRegular">Blog</h6>
                          </a>
                          <a href="/Contacto" class="a_none_style text-white">
                            <h6 class="oRegular">Contacto</h6>
                          </a>
                          {/* <a href="https://app.percapita.mx/login" class="a_none_style text-white">
                            <h6 class="oRegular">Inicia Sesión</h6>
                          </a> */}
                          <a href="#" class="a_none_style text-white">
                            <h6 class="oRegular">Inicia Sesión</h6>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-1"></div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-3">
                      <div class="legal">
                        <h3 class="rExtraBold txt-azul">Legal</h3>
                        <div class="mt-3">
                          <a
                            href="/Terminos-y-condiciones"
                            class="a_none_style text-white"
                          >
                            <h6 class="oRegular">Términos y Condiciones</h6>
                          </a>
                          <a
                            href="/Aviso-de-privacidad"
                            class="a_none_style text-white"
                          >
                            <h6 class="oRegular">Aviso de privacidad</h6>
                          </a>
                          <a
                            //href='https://percapita.mx/catycomisiones/catycomisiones.pdf'
                            href="/Cat-y-comisiones"
                            target="_blank"
                            class="a_none_style text-white"
                          >
                            <h6 class="oRegular">CAT y Comisiones</h6>
                          </a>
                          <a href="/Faq" class="a_none_style text-white">
                            <h6 class="oRegular">Preguntas frecuentes</h6>
                          </a>
                          <a
                            href="/Derechos-arco"
                            class="a_none_style text-white"
                          >
                            <h6 class="oRegular">Derechos de Arco</h6>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-2">
                      <div class="redes">
                        <h3 class="rExtraBold txt-azul">Síguenos</h3>
                        <div class="mt-5 text-center">
                          <a
                            href="https://www.facebook.com/PercapitaPrestamos"
                            class="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={icono_fb}
                              alt="FB: PERCAPITA"
                              class="img-fluid me-3"
                            />
                          </a>
                          {/* <a href="#" class="a_none_style text-white" target="_blank" rel="noopener noreferrer">
                            <img src={icono_ig} alt="IG: PERCAPITA" class="img-fluid me-3" />
  </a>*/}
                          <a
                            href="https://www.youtube.com/channel/UCsqIIu_msdAzHDzHDdUK7Qg"
                            class="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={icono_yt}
                              alt="YT: PERCAPITA"
                              class="img-fluid"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr class="hr-footer" />
                  <div>
                    <h5 class="oRegular contacto-footer txt-blanco">
                      PROBENSO GROUP S.A SOFOM E.N.R
                    </h5>
                    <h6 class="oRegular txt-ayuda txt-blanco">
                      Calle Tomás V. Gómez 307, Ladrón de Guevara C.P., 44600. Guadalajara, Jal. Mexico
                    </h6>
                  </div>
                  <hr></hr>
                  <div>
                    <h5 class="oRegular txt-blanco">
                      Atención a clientes:
                    </h5>
                    <div class="row">
                      <h7 class="oRegular txt-derechos txt-blanco text-number">
                        Atención en WhatsApp: al {" "} 
                        <a href="https://api.whatsapp.com/send?phone=523346548542" class="txt-blanco">
                          3346548542
                        </a>
                        {" "} de lunes a viernes de 9:00 a 18 horas y sábados de 9:00 a 14:00 horas.
                      </h7>
                      <h7 class="oRegular txt-derechos txt-blanco text-number">
                        Atención telefónica: al {" "} 
                        <a href="tel:+523336168059" class="txt-blanco">
                          3336168059
                        </a>
                        {" "} de lunes a viernes de 9:00 a 18 horas y sábados de 9:00 a 14:00 horas.
                      </h7>
                    </div>
                  </div>
                  <hr></hr>
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4">
                      <h5 class="oRegular txt-derechos">
                        Percapita 2023 ® Todos Los Derechos Reservados.
                      </h5>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4"></div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4">
                      <div class="text-end">
                        <img
                          src={materialized_blueberry}
                          alt="Materialized by Blueberry"
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-1"></div>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <div class="d-block d-sm-block d-md-none">
        <footer>
          <div class="bg-footer">
            <div class="container py-5">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-1"></div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-10">
                  <div class="text-center">
                    <img src={logo_balnco} alt="PERCAPITA" class="img-fluid" />
                  </div>
                  <div class="row my-5">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 mb-4">
                      <div class="contacto-footer mx-4">
                        <h3 class="rExtraBold txt-azul">Contacto</h3>
                        <div class="mt-3">
                          
                        <aside>
                            <a
                              href="https://maps.app.goo.gl/hvWmTwuNVcjdDCLi6"
                              className="a_none_style text-white"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h6 className="oRegular">
                              Calle Tomás V. Gómez 307
                              </h6> 
                              <h6 className="oRegular">
                              Ladrón de Guevara, Ladrón De Guevara
                              </h6>
                              <h6 className="oRegular">
                              44600 Guadalajara, Jal.
                              </h6>
                            </a>
                          </aside>
                          <div className="border-top my-3"></div>
                          <aside>
                            <a
                              href="https://maps.app.goo.gl/hvWmTwuNVcjdDCLi6"
                              class="a_none_style text-white"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h6 class="oRegular">
                              Ures Sur 137 5 
                              </h6>
                              <h6 class="oRegular">
                              Col. Centro, 63000 
                              </h6>
                              <h6 class="oRegular">
                              Tepic, Nayarit.
                              </h6>
                            </a>
                          </aside>
                          <a
                            href="mailto:contacto@percapita.mx?Subject=Solicitar%20Informes%20Percapita"
                            class="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h6 class="oRegular my-3">contacto@percapita.mx</h6>
                          </a>
                          <a
                            href="tel:+523336168059"
                            class="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h6 class="oRegular mb-3">33 3616-8059</h6>
                          </a>
                          <img
                            src={icono_what}
                            alt="Contactanos al:"
                            class="img-fluid icono-contacto"
                          />
                          <a
                            href="https://api.whatsapp.com/send?phone=523340094310"
                            class="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h6 class="oRegular">33 4009 4310</h6>
                          
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-2 mb-4">
                      <div class="menu-footer mx-4">
                        <h3 class="rExtraBold txt-azul">Menú</h3>
                        <div class="mt-3">
                          <a href="/" class="a_none_style text-white">
                            <h6 class="oRegular">Inicio</h6>
                          </a>
                          <a href="/Nosotros" class="a_none_style text-white">
                            <h6 class="oRegular">Nosotros</h6>
                          </a>
                          {/* <a href="/Financiamiento" class="a_none_style text-white">
                            <h6 class="oRegular">Financiamiento</h6>
                          </a> */}
                          {/* <a href="https://app.percapita.mx/" class="a_none_style text-white">
                            <h6 class="oRegular">Crédito PABS</h6>
                          </a> */}
                          <a
                            href="https://app.percapita.mx/Titulo?utm_source=percapita.mx&utm_medium=search&utm_campaign=none"
                            class="a_none_style text-white"
                          >
                            <h6 class="oRegular">Crédito PABS</h6>
                          </a>
                          <a
                            href="https://percapita.mx/Credito-personal"
                            class="a_none_style text-white"
                          >
                            <h6 class="oRegular">Crédito Personal</h6>
                          </a>
                          <a href="/Blog" class="a_none_style text-white">
                            <h6 class="oRegular">Blog</h6>
                          </a>
                          <a href="/Contacto" class="a_none_style text-white">
                            <h6 class="oRegular">Contacto</h6>
                          </a>
                          {/* <a href="https://app.percapita.mx/login" class="a_none_style text-white">
                            <h6 class="oRegular">Inicia Sesión</h6>
                          </a> */}
                          <a href="#" class="a_none_style text-white">
                            <h6 class="oRegular">Inicia Sesión</h6>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-1"></div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-3">
                      <div class="legal mx-4">
                        <h3 class="rExtraBold txt-azul">Legal</h3>
                        <div class="mt-3">
                          <a
                            href="/Terminos-y-condiciones"
                            class="a_none_style text-white"
                          >
                            <h6 class="oRegular">Términos y Condiciones</h6>
                          </a>
                          <a
                            href="/Aviso-de-privacidad"
                            class="a_none_style text-white"
                          >
                            <h6 class="oRegular">Aviso de privacidad</h6>
                          </a>
                          <a
                            //href='https://percapita.mx/catycomisiones/catycomisiones.pdf'
                            href="/Cat-y-comisiones"
                            target="_blank"
                            class="a_none_style text-white"
                          >
                            <h6 class="oRegular">CAT y Comisiones</h6>
                          </a>
                          {/* <a href="#" class="a_none_style text-white">
                            <h6 class="oRegular">Políticas de Cookies</h6>
                          </a> */}
                          <a href="/Faq" class="a_none_style text-white">
                            <h6 class="oRegular">Preguntas frequentes</h6>
                          </a>
                          <a
                            href="/Derechos-arco"
                            class="a_none_style text-white"
                          >
                            <h6 class="oRegular">Derechos Arco</h6>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-2">
                      <div class="redes mx-4">
                        <h3 class="rExtraBold txt-azul">Síguenos</h3>
                        <div class="mt-3">
                          <a
                            href="https://www.facebook.com/PercapitaPrestamos"
                            class="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={icono_fb}
                              alt="FB: PERCAPITA"
                              class="img-fluid me-3"
                            />
                          </a>
                          {/* <a href="#" class="a_none_style text-white" target="_blank" rel="noopener noreferrer">
                            <img src={icono_ig} alt="IG: PERCAPITA" class="img-fluid me-3" />
                          </a> */}
                          <a
                            href="https://www.youtube.com/channel/UCsqIIu_msdAzHDzHDdUK7Qg"
                            class="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={icono_yt}
                              alt="YT: PERCAPITA"
                              class="img-fluid"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr class="hr-footer" />
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4">
                      <h5 class="oRegular txt-derechos text-center">
                        © 2022 Percapita. Todos Los <br /> Derechos Reservados.
                      </h5>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4"></div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4">
                      <div class="text-center">
                        <img
                          src={materialized_blueberry}
                          alt="Materialized by Blueberry"
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-1"></div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};
