import React, { useState } from 'react';
import { Footer } from '../../front/Footer';
import { Navbar } from '../../front/Navbar';
import { useAnimation, motion } from 'framer-motion';
import icon_percapita from '../../../img/icon-percapita.png';
import percapitaLogo from '../../../img/percapita-logo.png';

export const CatYComisiones = () => {
  const [showTable, setShowTable] = useState(1);
  const animation2 = useAnimation();

  const mCSS = `
    .tg  {border-collapse:collapse;border-spacing:0;}
    .tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
      overflow:hidden;padding:10px 5px;word-break:normal;}
    .tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
      font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}
    .tg .tg-9wq8{border-color:inherit;text-align:center;vertical-align:middle}
    .tg .tg-09wn{background-color:#246da2;border-color:inherit;color:#ffffff;font-weight:bold;text-align:center;vertical-align:middle}
    .tg .tg-c3ow{border-color:inherit;text-align:center;vertical-align:top}
    .tg .tg-uzvj{border-color:inherit;font-weight:bold;text-align:center;vertical-align:middle}
    .tg .tg-htdb{border-color:inherit;font-weight:bold;text-align:center;vertical-align:middle;font-size:16px;}
  `;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: 'easeInOut', duration: 0.6 }}
    >
      <Navbar />
      <div className="bg-blog">
        <div className="container">
          <div className="row ">
            <div className="text-center pt-1">
              <img
                src={icon_percapita}
                alt="PERCAPITA"
                className="img-fluid  icono-headers"
              />
              <h1 className="rBlack display-4 mt-0">Cat y Comisiones</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-1">
        <div className="row pb-5">
          <div className="col-12 col-sm-12 col-md-12 col-lg-1"></div>
          <div className="col-12 col-sm-12 col-md-12 col-md-lg-11"
               style={{ display: showTable === 1 ? 'block' : 'none' }}>
            <div className="row">
              <motion.div
                animate={animation2}
                className="col-12 col-md-12 col-md-12 col-lg-12 pt-5"
              >
                <div className="mx-2">
                  <div
                    className="bg-gris py-5"
                    style={{ overflowX: 'auto' }}
                  >
                    <div className="mx-5">
                      <style type="text/css">
                        {mCSS}
                      </style>
                      <table className="tg"
                      >
                        <thead>
                        <tr>
                          <th className="tg-uzvj" colSpan="2">
                            <img src={percapitaLogo} alt="PRADO'S" className="img-fluid" />
                          </th>
                          <th className="tg-htdb" colSpan="6">PROBENSO GROUP, S.A., S.O.F.O.M., E.N.R.</th>
                        </tr>
                        <tr>
                          <th className="tg-09wn" colSpan="8">Información de Comisiones de Nuestros Productos</th>
                        </tr>
                        <tr>
                          <th className="tg-" colSpan="8"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td className="tg-uzvj" colSpan="2">Nuestros Productos</td>
                          <td className="tg-uzvj">Concepto</td>
                          <td className="tg-uzvj">Importe de Comisión</td>
                          <td className="tg-uzvj">Referencia</td>
                          <td className="tg-uzvj">Tasa de interés</td>
                          <td className="tg-uzvj">Tasa de interés moratoria</td>
                          <td className="tg-uzvj">CAT Promedio sin IVA. <br></br>Calculado al 01 de enero del 2024.
                            Vigencia al 30 de septiembre del 2024
                          </td>
                        </tr>
                        <tr>
                          <td className="tg-9wq8">2874-140-008842/11-00168-0123</td>
                          <td className="tg-9wq8">Crédito personal con garantía</td>
                          <td className="tg-9wq8">El producto no prevé comisión alguna</td>
                          <td className="tg-9wq8">-</td>
                          <td className="tg-9wq8">-</td>
                          <td className="tg-uzvj">164.00% fija</td>
                          <td className="tg-uzvj">0.00%</td>
                          <td className="tg-uzvj">205.4%</td>
                        </tr>
                        <tr>
                          <td className="tg-9wq8">2874-140-027459/07-03045-1124</td>
                          <td className="tg-9wq8">Préstamo Personal</td>
                          <td className="tg-9wq8">Comisión por contratación o apertura de línea de crédito</td>
                          <td className="tg-9wq8">5%</td>
                          <td className="tg-9wq8">Sobre monto o línea de crédito por única vez</td>
                          <td className="tg-uzvj">300.00% fija</td>
                          <td className="tg-uzvj">0.00%</td>
                          <td className="tg-uzvj">296.6%</td>
                        </tr>
                        <tr>
                          <td className="tg-c3ow" colSpan="8"></td>
                        </tr>
                        <tr>
                          <td className="tg-uzvj" colSpan="8">Para más consultas puede ingresar a:
                            <a href="https://www.condusef.gob.mx" target="_blank"
                               rel="noreferrer">www.condusef.gob.mx</a>
                          </td>
                        </tr>
                        <tr>
                          <td className="tg-9wq8" colSpan="2">Teléfono de la SOFOM:</td>
                          <td className="tg-9wq8" colSpan="6">33 3616 8059</td>
                        </tr>
                        <tr>
                          <td className="tg-9wq8" colSpan="2">Página web de la SOFOM:</td>
                          <td className="tg-9wq8" colSpan="6"><a href="https://percapita.mx/" target="_blank"
                                                                 rel="noopener noreferrer">https://percapita.mx/</a>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-md-lg-11"
               style={{ display: showTable === 2 ? 'block' : 'none' }}>
            <div className="row">
              <motion.div
                animate={animation2}
                className="col-12 col-md-12 col-md-12 col-lg-12 pt-5"
              >
                <div className="mx-2">
                  <div
                    className="bg-gris py-5"
                    style={{ overflowX: 'auto' }}
                  >
                    <div className="mx-5">
                      <style type="text/css">
                        {mCSS}
                      </style>
                      <table className="tg"
                      >
                        <thead>
                        <tr>
                          <th className="tg-uzvj" colSpan="2">
                            <img src={percapitaLogo} alt="PRADO'S" className="img-fluid" />
                          </th>
                          <th className="tg-htdb" colSpan="6">PROBENSO GROUP, S.A., S.O.F.O.M., E.N.R.</th>
                        </tr>
                        <tr>
                          <th className="tg-09wn" colSpan="8">Información de Comisiones de Nuestros Productos</th>
                        </tr>
                        <tr>
                          <th className="tg-" colSpan="8"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td className="tg-uzvj" colSpan="2">Nuestros Productos</td>
                          <td className="tg-uzvj">Concepto</td>
                          <td className="tg-uzvj">Importe de Comisión</td>
                          <td className="tg-uzvj">Referencia</td>
                          <td className="tg-uzvj">Tasa de interés</td>
                          <td className="tg-uzvj">Tasa de interés moratoria</td>
                          <td className="tg-uzvj">CAT Promedio sin IVA. <br></br>Calculado al 01 de enero del 2024.
                            Vigencia al 30 de septiembre del 2024
                          </td>
                        </tr>
                        <tr>
                          <td className="tg-9wq8">2874-138-033587/03-00119-0123</td>
                          <td className="tg-9wq8">Crédito Hipotecario ARTPARK JMMB</td>
                          <td className="tg-9wq8">Comisión por apertura de línea de crédito</td>
                          <td className="tg-9wq8">0.50%</td>
                          <td className="tg-9wq8">Factor sobre el monto del crédito</td>
                          <td className="tg-uzvj">10.65% variable</td>
                          <td className="tg-uzvj">0.00%</td>
                          <td className="tg-uzvj">13.5%</td>
                        </tr>
                        <tr>
                          <td className="tg-9wq8">2874-138-033586/03-00158-0123</td>
                          <td className="tg-9wq8">Crédito Hipotecario CREDITO JMMB</td>
                          <td className="tg-9wq8">Comisión por apertura de línea de crédito</td>
                          <td className="tg-9wq8"><b>-</b></td>
                          <td className="tg-9wq8"><b>-</b></td>
                          <td className="tg-uzvj">20.65% mixta</td>
                          <td className="tg-uzvj">0.00%</td>
                          <td className="tg-uzvj">28.2%</td>
                        </tr>
                        <tr>
                          <td className="tg-9wq8">2874-138-033585/03-030104-0123</td>
                          <td className="tg-9wq8">Crédito Hipotecario CREDITO ARTPARK</td>
                          <td className="tg-9wq8">Comisión por apertura de línea de crédito</td>
                          <td className="tg-9wq8">0.45%</td>
                          <td className="tg-9wq8">Factor sobre el monto del crédito</td>
                          <td className="tg-uzvj">18% fija</td>
                          <td className="tg-uzvj">0.00%</td>
                          <td className="tg-uzvj">27.8%</td>
                        </tr>
                        <tr>
                          <td className="tg-c3ow" colSpan="8"></td>
                        </tr>
                        <tr>
                          <td className="tg-uzvj" colSpan="8">Para más consultas puede ingresar a: <a
                            href="https://www.condusef.gob.mx" target="_blank" rel="noreferrer">www.condusef.gob.mx</a>
                          </td>
                        </tr>
                        <tr>
                          <td className="tg-9wq8" colSpan="2">Teléfono de la SOFOM:</td>
                          <td className="tg-9wq8" colSpan="6">33 3616 8059</td>
                        </tr>
                        <tr>
                          <td className="tg-9wq8" colSpan="2">Página web de la SOFOM:</td>
                          <td className="tg-9wq8" colSpan="6"><a href="https://percapita.mx/" target="_blank"
                                                                 rel="noopener noreferrer">https://percapita.mx/</a>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-md-lg-11"
               style={{ display: showTable === 3 ? 'block' : 'none' }}>
            <div className="row">
              <motion.div
                animate={animation2}
                className="col-12 col-md-12 col-md-12 col-lg-12 pt-5"
              >
                <div className="mx-2">
                  <div
                    className="bg-gris py-5"
                    style={{ overflowX: 'auto' }}
                  >
                    <div className="mx-5">
                      <style type="text/css">
                        {mCSS}
                      </style>
                      <table className="tg">
                        <thead>
                        <tr>
                          <th className="tg-uzvj" colSpan="2">
                            <img src={percapitaLogo} alt="PRADO'S" className="img-fluid" />
                          </th>
                          <th className="tg-htdb" colSpan="6">PROBENSO GROUP, S.A., S.O.F.O.M., E.N.R.</th>
                        </tr>
                        <tr>
                          <th className="tg-09wn" colSpan="8">Información de Comisiones de Nuestros Productos</th>
                        </tr>
                        <tr>
                          <th className="tg-" colSpan="8"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td className="tg-uzvj" colSpan="2">Nuestros Productos</td>
                          <td className="tg-uzvj">Concepto</td>
                          <td className="tg-uzvj">Importe de Comisión</td>
                          <td className="tg-uzvj">Referencia</td>
                          <td className="tg-uzvj">Tasa de interés</td>
                          <td className="tg-uzvj">Tasa de interés moratoria</td>
                          <td className="tg-uzvj">CAT Promedio sin IVA. <br></br>Calculado al 01 de enero del 2024.
                            Vigencia al 30 de septiembre del 2024
                          </td>
                        </tr>
                        <tr>
                          <td className="tg-9wq8">2874-439-026962/05-00175-0123</td>
                          <td className="tg-9wq8">Crédito simple Préstamo Empresarial</td>
                          <td className="tg-9wq8">El producto no prevé comisión alguna</td>
                          <td className="tg-9wq8">-</td>
                          <td className="tg-9wq8">-</td>
                          <td className="tg-uzvj">10% fija</td>
                          <td className="tg-uzvj">0%</td>
                          <td className="tg-uzvj">12.2%</td>
                        </tr>
                        <tr>
                          <td className="tg-9wq8">2874-140-013302/07-00167-0123</td>
                          <td className="tg-9wq8">Crédito personal sin garantía</td>
                          <td className="tg-9wq8">Comisión por contratación o apertura de línea de crédito</td>
                          <td className="tg-9wq8">1%</td>
                          <td className="tg-9wq8">Sobre monto o línea de crédito por única vez</td>
                          <td className="tg-uzvj">36% fija</td>
                          <td className="tg-uzvj">0%</td>
                          <td className="tg-uzvj">43.2%</td>
                        </tr>
                        <tr>
                          <td className="tg-c3ow" colSpan="8"></td>
                        </tr>
                        <tr>
                          <td className="tg-uzvj" colSpan="8">Para más consultas puede ingresar a:
                            <a href="https://www.condusef.gob.mx" target="_blank"
                               rel="noreferrer">www.condusef.gob.mx</a>
                          </td>
                        </tr>
                        <tr>
                          <td className="tg-9wq8" colSpan="2">Teléfono de la SOFOM:</td>
                          <td className="tg-9wq8" colSpan="6">33 3616 8059</td>
                        </tr>
                        <tr>
                          <td className="tg-9wq8" colSpan="2">Página web de la SOFOM:</td>
                          <td className="tg-9wq8" colSpan="6"><a href="https://percapita.mx/" target="_blank"
                                                                 rel="noopener noreferrer">https://percapita.mx/</a>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-md-lg-11">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-4 pt-5 text-center">
                <div className="mt-4">
                  <button className="btn btn-primary btn-conoce-azul" onClick={() => setShowTable(1)}
                          disabled={showTable === 1}>
                    <h6 className="rBlack txt-azul-marino pt-1">Crédito Personal</h6>
                  </button>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-4 pt-5 text-center">
                <div className="mt-4">
                  <button className="btn btn-primary btn-conoce-azul" onClick={() => setShowTable(2)}
                          disabled={showTable === 2}>
                    <h6 className="rBlack txt-azul-marino pt-1">Crédito Hipotecario</h6>
                  </button>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-4 pt-5 text-center">
                <div className="mt-4">
                  <button className="btn btn-primary btn-conoce-azul" onClick={() => setShowTable(3)}
                          disabled={showTable === 3}>
                    <h6 className="rBlack txt-azul-marino pt-1">Crédito Empresarial</h6>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </motion.div>
  );
};
